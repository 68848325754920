<template>
    <div class="relative card-tail card-f-h">
        <ModalComments :visible="commentsModalVisible" :source-id="selectedCommentSourceId" @close="commentsModalVisible = false" />

        <el-tabs v-model="activeTab" @tab-click="tabChanged">
            <el-tab-pane label="Påminnelser" name="pominelserContainer" lazy>
                <TablePaminelser ref="pominelserContainer" @showCommentsModal="showCommentsModal" v-if="activeTab === 'pominelserContainer'" />
            </el-tab-pane>
            <el-tab-pane label="Påminnelser 2" name="pominelser2Container" lazy>
                <TablePaminelser2 ref="pominelser2Container" @showCommentsModal="showCommentsModal" v-if="activeTab === 'pominelser2Container'" />
            </el-tab-pane>
            <el-tab-pane label="Utskrifter" name="utskrifterContainer" lazy>
                <TableUtskrifter ref="utskrifterContainer" @showCommentsModal="showCommentsModal" v-if="activeTab === 'utskrifterContainer'" />
            </el-tab-pane>
            <el-tab-pane label="Inkasso" name="inkassoContainer" lazy>
                <TableInkasso ref="inkassoContainer" @showCommentsModal="showCommentsModal" v-if="activeTab === 'inkassoContainer'" />
            </el-tab-pane>
            <el-tab-pane label="Bevakas" name="bevakasContainer" lazy>
                <TableBevakas ref="bevakasContainer" @showCommentsModal="showCommentsModal" v-if="activeTab === 'bevakasContainer'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeTab: "pominelserContainer",
            commentsModalVisible: false,
            selectedCommentSourceId: null,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Att åtgärda");
        document.title = "Att åtgärda";
    },

    components: {
        TablePaminelser: () => import("./components/AttAtgardaTablePaminelser"),
        TablePaminelser2: () => import("./components/AttAtgardaTablePaminelser2"),
        TableBevakas: () => import("./components/AttAtgardaTableBevakas"),
        TableUtskrifter: () => import("./components/AttAtgardaTableUtskrifter"),
        TableInkasso: () => import("./components/AttAtgardaTableInkasso"),
        ModalComments: () => import(/* webpackChunkName: "AttAtgardaCommentsModal" */ "./modals/AttAtgardaCommentsModal.vue"),
    },

    methods: {
        showCommentsModal(id) {
            this.selectedCommentSourceId = id;
            this.commentsModalVisible = true;
        },

        tabChanged(event) {
            this.$refs[event.name]?.getAll();
        },
    },
};
</script>
<style>
.el-tabs__content {
    overflow: visible;
}
</style>
